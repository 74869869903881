@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter+Moments&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://unpkg.com/browse/antd@5.0.0/dist/reset.css");

:root {
  --primary-color: #ff7731;
  --secondary-color: #faa935;
  --heading-color: #0b2727;
  --text-color: #6e7074;
  --font-name: "Inter", sans-serif !important;
  --subtitle-font-name: "Montserrat", cursive;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: 500;
  font-family: "Inter", sans-serif !important;
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
}

a {
  text-decoration: none;
  color: unset;
}

ul {
  list-style: none;
}

b {
  font-weight: bold;
}
//TODO: VPBANK
.ant-select {
  .ant-select-selector {
    &:focus,
    &:hover {
      border-color: #01ad53 !important;
    }
  }
}

.ant-picker {
  &:focus,
  &:hover {
    border-color: #01ad53 !important;
  }
  .ant-picker-input {
    &:focus,
    &:hover {
      border-color: #01ad53 !important;
    }
    input {
      &:focus,
      &:hover {
        border-color: #01ad53 !important;
      }
    }
  }
  .ant-picker-outlined {
    &:focus,
    &:hover {
      border-color: #01ad53 !important;
    }
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: #01ad53 !important;
    border-color: #01ad53 !important;
  }
}

.ant-checkbox {
  .ant-checkbox-inner {
    border-color: #01ad53 !important;
  }
}

.ant-input {
  &:focus,
  &:hover {
    border-color: #01ad53 !important;
  }
}

.ant-input-affix-wrapper {
  &:focus,
  &:hover {
    border-color: #01ad53 !important;
  }
}

.ant-radio {
  .ant-radio-inner {
    border-color: #01ad53 !important;
  }
}

.ant-radio-checked {
  .ant-radio-inner {
    border-color: #01ad53 !important;
    background-color: #01ad53 !important;
  }
}

.ant-picker-cell-range-start .ant-picker-cell-inner {
  background-color: #01ad53 !important;
}

.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #01ad53 !important;
}

.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: #01ad53 !important;
  border-color: #01ad53 !important;
}

.ant-picker-cell-today  .ant-picker-cell-inner::before {
  border-color: #01ad53 !important;
}

.ant-picker-ok button {
   background-color: #01ad53 !important;
}

.ant-picker-active-bar {
  background-color: #01ad53 !important;
}

.ant-pagination-item-active {
  border-color: #01ad53 !important;
  a {
    color: #01ad53 !important;
  }
}

.ant-form-item-explain-error {
  color: #ec1c24 !important;
}
.pac-target-input {
  &:focus,
  &:hover {
    border-color: #01ad53 !important;
  }
}

.ant-pagination {
  .ant-pagination-item-container {
    background-color: white;
  }
  .ant-pagination-next,
  .ant-pagination-prev {
    button {
      background-color: white !important;
    }
  }
}

.ant-picker-no-left {
  left: 0px !important;
  .ant-picker-time-panel {
    .ant-picker-time-panel-cell.ant-picker-time-panel-cell-selected {
      .ant-picker-time-panel-cell-inner {
        background-color: #01ad53 !important;
        color: white;
      }
    }
  }
}

.ant-picker-dropdown {
  left: 40px;
  .ant-picker-time-panel {
    .ant-picker-time-panel-cell.ant-picker-time-panel-cell-selected {
      .ant-picker-time-panel-cell-inner {
        background-color: #01ad53 !important;
        color: white;
      }
    }
  }
}
/* Tùy chỉnh thanh cuộn của Select */
.custom-scroll {
  &::-webkit-scrollbar {
    width: 5px !important;
  }
  &::-webkit-scrollbar-track {
    background: transparent !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e2e2e2 !important;
    border-radius: 8px !important;
    border: 1px solid transparent !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #e2e2e2 !important;
  }
}
.ant-modal-root {
  .ant-modal-content {
    .ant-modal-header {
      .ant-modal-title {
        color: #01ad53 !important;
        font-size: 20px !important;
      }
    }
  }
  .car-selected {
    filter: none;
    border: 4px solid #01ad53;
    border-radius: 50%;
    cursor: pointer;
  }
  .car-not-selected {
    filter: grayscale(100%) !important;
    cursor: pointer;
  }
}
.booking {
  .autocomplete-container {
    position: relative;
    width: 100%;
  }
  
  .autocomplete-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    &:focus {
      border-color: #01ad53 !important;
    }
  }
  
  .autocomplete-suggestions {
    position: absolute;
    width: 100%;
    background: white;
    border: 1px solid #ddd;
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
    z-index: 100;
  }
  
  .autocomplete-suggestions li {
    padding: 8px;
    cursor: pointer;
  }
  
  .autocomplete-suggestions li:hover {
    background: #f0f0f0;
  }
}
.step1 {
  .border-error {
    border: 1px solid #ff4d4f !important;
  }
  .add-start-container {
    position: relative;
    width: 100%;

    input {
      height: 40px;
      font-size: 14px;
      border: 1px solid #e6e8e9;
      width: 100%;
      background-color: #f5f6f6 !important;
      padding-left: 40px;
      border-radius: 8px;
      // border-bottom-left-radius: 8px;
      outline: none;
      &:focus {
        border-color: #01ad53 !important;
      }
    }
    // &::before {
    //   content: "";
    //   position: absolute;
    //   left: 10px;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   background-size: cover;
    //   background-repeat: no-repeat;
    //   width: 20px;
    //   height: 20px;
    //   background-image: url("../public/images/ic-circle.svg");
    // }
  }

  .add-end-container {
    position: relative;
    width: 100%;
    input {
      height: 40px;
      font-size: 14px;
      border: 1px solid #e6e8e9;
      width: 100%;
      background-color: #f5f6f6 !important;
      padding-left: 40px;
      border-radius: 8px;
      outline: none;
      &:focus {
        border-color: #01ad53 !important;
      }
    }
    // &::before {
    //   content: "";
    //   position: absolute;
    //   left: 12px;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   background-size: auto;
    //   background-repeat: no-repeat;
    //   width: 16px;
    //   height: 20px;
    //   background-image: url("../public/images/ic-pin.svg");
    // }
  }

  .add-stop-container {
    position: relative;
    width: 100%;
    input {
      height: 40px;
      font-size: 14px;
      border: 1px solid #e6e8e9;
      border-right: 0;
      width: 100%;
      background-color: #f5f6f6 !important;
      padding-left: 40px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      outline: none;
      &:focus {
        border-color: #01ad53 !important;
      }
    }
    &::before {
      content: "";
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
      background-size: auto;
      background-repeat: no-repeat;
      width: 16px;
      height: 20px;
      background-image: url("../public/images/ic_diemdung.png");
    }
  }

  .form-search {
    // new
    .ant-form-item {
      .ant-form-item-label {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
      }
      .ant-form-item-control-input-content {
        input {
          height: 40px;
          border-color: #e6e8e9;
          background-color: #f5f6f6 !important;
          border-radius: 8px;
        }
      }
      .ant-input-affix-wrapper {
        background-color: #f5f6f6 !important;
        border-color: #e6e8e9;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        height: 40px;
        line-height: 40px !important;
        input {
          height: 33px !important;
        }
      }
      .ant-input-group-addon {
        button {
          height: 40px;
        }
      }
      .ant-select {
        border-color: #e6e8e9;
        border-radius: 8px;
        position: relative;
        .ant-select-selector {
          padding-left: 40px;
          background-color: #f5f6f6 !important;
          .ant-select-selection-search {
            input {
              padding-left: 30px;
            }
          }
          .ant-select-selection-item {
            padding-right: 0px;
            font-size: 14px;
          }
        }
        .ant-select-arrow {
          height: 100%;
          position: absolute;
          left: 10px; /* Adjust as needed */
          top: 6px;
          pointer-events: none;
        }
      }
      .ant-form-item-label {
        label {
          color: #22313f;
        }
      }
    }

    .ant-segmented-group {
      .ant-segmented-item-label {
        color: #22313f;
      }
      .ant-segmented-item-selected {
        background-color: #01ad53;
        .ant-segmented-item-label {
          color: white;
        }
      }
    }
    // end new
  }
  .ant-switch.ant-switch-checked {
    background-color: white !important;
    border: 2px solid #01ad53;
    .ant-switch-handle {
      inset-inline-start: calc(100% - 18px);
      top: 0px;
    }
    .ant-switch-handle::before {
      background-color: #01ad53;
    }
  }
}

.ant-picker,
.ant-picker-range {
  background-color: #f5f6f6;
  border-radius: 8px;
  .ant-picker-input {
    input {
      height: 25px !important;
    }
  }
  .ant-picker-suffix {
    height: 100%;
    position: absolute;
    left: "-5px" !important; /* Adjust as needed */
    top: 0;
    pointer-events: none;
    margin: 0 !important;
    padding: 0 !important;
    img {
      width: 20px;
    }
  }
  .ant-picker-input {
    input {
      font-size: 14px;
      color: #22313f;
      padding-left: 23px;
    }
  }
}

.ant-descriptions {
  .ant-descriptions-header {
    .ant-descriptions-title {
      font-size: 20px !important;
      color: #01ad53 !important;
    }
  }
  .ant-descriptions-view {
    tr th,
    tr td {
      padding: 12px !important;
    }
  }
}

.ant-picker-panel {
  &:last-child {
    width: 0;

    .ant-picker-header {
      position: absolute;
      right: 0;

      .ant-picker-header-prev-btn,
      .ant-picker-header-view {
        visibility: hidden;
      }
    }

    .ant-picker-body {
      display: none;
    }

    @media (min-width: 768px) {
      width: 280px !important;

      .ant-picker-header {
        position: relative;

        .ant-picker-header-prev-btn,
        .ant-picker-header-view {
          visibility: initial;
        }
      }

      .ant-picker-body {
        display: block;
      }
    }
  }
}

.calender-active {
  border: 1px solid #d72f20;
}
